.banner-widget {
  cursor: pointer;
  box-sizing: border-box;
}

.banner-widget-inner {
  height: 100%;
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
}

.banner-widget-image-template {
  width: 399.96px;
  height: 140px;
  background: #f2f2f2;
  border: 1px solid #bdbdbd;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #b5b5c3;
  font-size: 14px;
  font-weight: 600;
  border-radius: 10px;
  text-align: center;

  &.active {
    border: 2px dashed #8dcd03;
  }
}

.banner-widget-image {
  &.active {
    border: 3px dashed #8dcd03;
  }
}
