.media-card {
  transition: background-color 0.3s ease;
  &:hover {
    background-color: #d6d6d6 !important;
    span {
      &:first-child {
        outline: 1px solid #007bff;
      }
    }
  }
}
