.section-container {
  min-height: 160px;
  border: 2px dashed #bdbdbd;
  margin-bottom: 8px;
  overflow-x: auto;
  display: flex;
  flex-wrap: wrap;
  padding: 4px 4px;
  position: relative;
  z-index: 1000;
}
