$spacing-0: 0;
$spacing-2xs: 0.125rem;
$spacing-xs: 0.25rem;
$spacing-sm: 0.4rem;
$spacing-md: 0.5rem;
$spacing-lg: 0.75rem;
$spacing-xl: 1rem;
$spacing-2xl: 1.5rem;
$spacing-3xl: 2.25rem;
$spacing-4xl: 3rem;
$spacing-5xl: 4rem;
