@import '../../styles/index';

.file-upload {
  width: 100%;
  padding: 15px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.input-file {
  width: 0;
  height: 0;
  visibility: hidden;
}

.input-file-box {
  width: 30%;
  min-height: 120px;
  background: rgba(245, 248, 250, 0.4);
  border: 0.0625rem dashed #b5b5c3;
  border-radius: 9px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.drag-drop-message {
  font-weight: 600;
  font-size: 18px;
  line-height: 32px;
  letter-spacing: -0.01em;
  color: #3f4254;
}

.select-file-message {
  text-decoration: underline;
  background-color: transparent;
  border: none;
  outline: none;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 18px;
  color: #b5b5c3;
  margin: 0;
  padding: 0;
}

.drag-over {
  border: solid 0.125rem $secondary-80;
}

.upload-box {
  width: 100%;
  min-height: 100px;
  margin: $spacing-lg 0;
  background: rgba(245, 248, 250, 0.4);
  border: 0.0625rem dashed #b5b5c3;
  max-height: 500px;
  overflow-y: auto;
  overflow-x: hidden;
}

.upload-box-partial-done,
.upload-box-uploading {
  border-color: $neutral-70;
}

.upload-box-error {
  border-color: $semantic-error;
}

.upload-box-done {
  border-color: $semantic-success;
}

.helper-text {
  color: $neutral-70;
  margin-top: $spacing-md;
  letter-spacing: -1%;
  line-height: 150%;
  font-weight: $font-weight-light;
  font-size: $font-sm;
}

.label {
  font-weight: $font-weight-medium;
  margin-right: $spacing-md;
}

.upload-message {
  margin: $spacing-md 0 $spacing-3xl;
  text-align: center;
  font-weight: $font-weight-light;
  font-size: $font-md;
}

.file-item {
  display: flex;
  margin: $spacing-xl 0;
  align-items: center;

  &-name {
    flex: 1;
    margin-left: $spacing-md;

    & p {
      width: 75%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      color: $text-default-100;
      font-size: $font-md;
      font-weight: $font-weight-regular;
      margin: 0;
      padding: 0;
    }
  }

  &-remove-button {
    background: transparent;
    border: none;
    margin: 0;
    padding: 0;
    cursor: pointer;
  }
}

.error {
  &-section {
    display: flex;
    margin-bottom: $spacing-md;
    align-items: center;
    color: $text-error;
  }

  &-message {
    margin-left: $spacing-sm;
    line-height: 150%;
    font-weight: $font-weight-light;
    font-size: $font-md;
    letter-spacing: -2%;
  }

  &-icon {
    width: 0.75rem;
    height: 0.75rem;
    background: $text-error;
  }

  & .label {
    color: $text-error;
  }

  & .select-field {
    border-color: $semantic-error;

    &:hover {
      border-color: $semantic-error;
    }
  }
}

.add-button {
  right: 15px;
  bottom: 15px;
}
