$primary-00: hsl(0 0% 100% / 100%);
$primary-100: hsl(0 0% 7% / 100%);
$secondary-00: hsl(139 100% 89% / 100%);
$secondary-10: hsl(146 100% 74% / 100%);
$secondary-20: hsl(156 93% 44% / 100%);
$secondary-80: hsl(156 100% 18% / 100%);
$secondary-90: hsl(156 100% 14% / 100%);

$neutral-00: hsl(0 0% 94% / 100%);
$neutral-20: hsl(0 0% 84% / 100%);
$neutral-30: hsl(0 0% 72% / 100%);
$neutral-40: hsl(0 0% 66% / 100%);
$neutral-70: hsl(0 0% 35% / 100%);
$neutral-80: hsl(0 0% 23% / 100%);

$semantic-success: hsl(162 55% 53% / 100%);
$semantic-error: hsl(1 78% 49% / 100%);

$text-success: hsl(163 96% 18% / 100%);
$text-error: hsl(1 97% 36% / 100%);
$text-default-100: hsl(0 0 7% / 100%);

$color-gray-1: hsla(0, 0%, 90%, 1);
$color-gray-2: hsla(0, 0%, 80%, 1);
$color-gray-3: hsla(0, 0%, 70%, 1);
$color-gray-4: hsla(0, 0%, 60%, 1);
$color-gray-5: hsla(0, 0%, 50%, 1);
$color-gray-6: hsla(0, 0%, 40%, 1);
$color-gray-7: hsla(0, 0%, 30%, 1);
$color-gray-8: hsla(0, 0%, 20%, 1);
$color-gray-9: hsla(0, 0%, 10%, 1);
$color-gray-10: hsla(0, 0%, 0%, 1);
