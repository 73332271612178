// Font Weight
$font-weight-light: 300;
$font-weight-regular: 400;
$font-weight-medium: 500;
$font-weight-bold: 700;

// Font Size
$font-xs: 0.6875rem;
$font-sm: 0.8125rem;
$font-md: 1rem;
$font-lg: 1.125rem;
$font-xl: 1.25rem;
$font-2xl: 1.5rem;
$font-3xl: 1.75rem;
$font-4xl: 2.5rem;
