.story-widget {
  box-sizing: border-box;
  width: 100px;
  height: 150px;
  cursor: pointer;
  &.active {
    border: 2px dashed #8dcd03;
  }
}

.story-widget-inner {
  height: 100%;
  width: 100%;
  padding: 0.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;

  & .story-widget-inner__img {
    flex: 1;
  }

  .story-widget-inner__title {
    flex: 2;
    margin-top: 1rem;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.5px;
    color: #5e6278;
    inline-size: 90px;
    overflow-wrap: break-word;
    overflow-y: auto;
    text-align: center;
    word-break: break-all;
    padding: 0.2rem;
  }
}
