.scale-on-hover:hover {
  scale: 3;
  transition: scale 0.3s;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.5);
}

.table-responsive {
  td {
    white-space: nowrap;
    padding: 0.3rem;
  }
}

.hover-block:hover {
  .d-inline-on-block-hover {
    display: inline !important;
  }
}

.stick-to-corner {
  position: absolute;
  right: 0px;
  top: 0px;
}
