.box-widget {
  cursor: pointer;
  box-sizing: border-box;
  width: 140px;
  height: 140px;
  background: #f2f2f2;
  margin-right: 0.5rem;

  &.active {
    border: 2px dashed #8dcd03;
  }
}

.text-widget {
  cursor: pointer;
  box-sizing: border-box;
  padding: 1.5rem;

  &.active {
    border: 2px dashed #8dcd03;
  }
}

.box-widget-inner {
  height: 100%;
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
}

.box-widget-title {
  font-weight: 400;
  font-size: 14px;
  color: #5e6278;

  &.active {
    font-weight: 600;
  }
}

.box-widget-image-template {
  width: 140px;
  height: 140px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #b5b5c3;
  font-size: 14px;
  font-weight: 600;
  text-align: center;
}
